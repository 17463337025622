import { Form, Formik } from 'formik';
import { LoginProps } from 'modules/users/use-cases/Login/types';
import { LoginSchema } from 'modules/users/use-cases/Login/schema';
import { NextPageWithLayout } from './_app';
import { SessionContext } from 'components/layout/SessionProvider';
import { login } from 'services/auth';
import DefaultLayout from 'components/layout/Default';
import Head from 'next/head';
import Link from 'next/link';
import Password from 'components/forms/Password';
import React, { ReactElement, useContext, useEffect } from 'react';
import Router from 'next/router';
import TextField from 'components/forms/TextField';
// import Image from 'next/image';

const Login: NextPageWithLayout = () => {
  const [sessionState, setSessionState] = useContext(SessionContext);
  const signIn = async (values: LoginProps) => {
    const loginResult = await login(values);
    if (loginResult.user) {
      setSessionState({ user: loginResult.user });
    }
  };

  useEffect(() => {
    if (sessionState.user) {
      if (sessionState.user.role === 'admin') {
        Router.push('/admin');
      } else {
        Router.push('/dashboard');
      }
    }
  }, [sessionState]);

  return (
    <>
      <Head>
        <title>Login</title>
      </Head>
      <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          {/* <Image
            className="mx-auto h-12 w-auto"
            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600"
            alt="Your Company"
            width={56}
            height={48}
          /> */}
          <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
            Sign in to your account
          </h2>
        </div>

        <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
          <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <Formik
              initialValues={{
                email: '',
                password: ''
              }}
              validationSchema={LoginSchema}
              onSubmit={signIn}
            >
              {({
                errors,
                touched,
                values,
                setFieldValue,
                setFieldError,
                validateField,
                setFieldTouched
              }) => (
                <Form className="space-y-6" action="#" method="POST">
                  <TextField
                    label={'Email'}
                    name={'email'}
                    type={'email'}
                    autocomplete={'email'}
                    value={values.email}
                    isDirty={touched.email}
                    hasError={!!errors.email}
                    errorMessage={errors.email || ''}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    validate={validateField}
                  />

                  <Password
                    label={'Password'}
                    name={'password'}
                    value={values.password}
                    isDirty={touched.password}
                    hasError={!!errors.password}
                    errorMessage={errors.password || ''}
                    setFieldValue={setFieldValue}
                    setFieldError={setFieldError}
                    setFieldTouched={setFieldTouched}
                    validate={validateField}
                  />

                  <div className="flex items-center justify-between">
                    <div className="text-sm">
                      <Link
                        href="/forgot-password"
                        className="font-medium text-indigo-600 hover:text-indigo-500"
                      >
                        Forgot your password?
                      </Link>
                    </div>
                  </div>

                  <div>
                    <button
                      type="submit"
                      className="flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    >
                      Sign in
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};

Login.getLayout = function getLayout(page: ReactElement) {
  return <DefaultLayout>{page}</DefaultLayout>;
};

export default Login;
