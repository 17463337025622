import { ChangeEvent, useEffect, useState } from 'react';
import {
  ExclamationCircleIcon,
  EyeIcon,
  EyeSlashIcon
} from '@heroicons/react/20/solid';
import { FormFieldProps } from './types';
import tw from 'tailwind-styled-components';

type StyledFieldProps = {
  showerror: string;
  value?: string;
  name: string;
};
const StyledField = tw.input<StyledFieldProps>`
  ${(props: StyledFieldProps) =>
    props.showerror
      ? 'border-red-300 text-red-900 placeholder-red-300 focus:border-red-500 focus:ring-red-500'
      : 'border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500'}
  block w-full rounded-md  pr-10  focus:outline-none  sm:text-sm'
`;

const Password = (props: FormFieldProps) => {
  const [fieldType, setFieldType] = useState<string>('password');
  const toggleFieldType = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setFieldType(fieldType === 'password' ? 'text' : 'password');
  };
  const [value, setValue] = useState(props.value || '');
  useEffect(() => {
    if (props.value !== value) {
      setValue(props.value);
    }
  }, [props.value]);

  const onChange = (ev: ChangeEvent<HTMLInputElement>) => {
    if (props.setFieldValue) {
      props.setFieldValue(props.name, ev.target.value, true);
    } else if (props.setFieldTouched) {
      props.setFieldTouched(props.name, true, true);
    } else if (props.validate) {
      props.validate(props.name);
    }
    setValue(ev.target.value);
  };
  const onBlur = (e: React.FocusEvent<any>) => {
    if (props.onBlur) {
      props.onBlur(e);
    }
  };
  return (
    <div>
      <label
        htmlFor={props.name}
        className="block text-sm font-medium text-gray-700"
      >
        {props.label}
      </label>
      <div className="relative mt-1 rounded-md shadow-sm">
        <StyledField
          name={props.name}
          value={props.value}
          type={fieldType}
          showerror={props.hasError && props.isDirty ? 'true' : 'false'}
          onChange={onChange}
          onBlur={onBlur}
        />
        <button
          className="absolute inset-y-0 right-0 flex items-center pr-3"
          onClick={toggleFieldType}
          type="button"
        >
          {fieldType === 'password' && (
            <EyeIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          )}
          {fieldType === 'text' && (
            <EyeSlashIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          )}
        </button>
        {props.hasError && props.isDirty && (
          <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
            <ExclamationCircleIcon
              className="h-5 w-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {props.hasError && props.isDirty ? (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {props.errorMessage || `${props.label} is invalid`}
        </p>
      ) : null}
    </div>
  );
};

export default Password;
